import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby"
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import Img from "gatsby-image/withIEPolyfill";
import { Panel } from "rsuite";
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import BackgroundImage from 'gatsby-background-image';

const Services = props => {
  const services = props.data.allMarkdownRemark.edges;
  const icons = props.data.allIconsJson.edges;
  const json = props.data.allFeaturesJson.edges;
  const location = props.pageContext.language;
  const intl = useIntl();

  return (
    <Layout bodyClass="page-services" path={props.location.pathname}>
      <SEO title={intl.formatMessage({ id: "Services_title" })} />
      <div className="services-img w-100"></div>
      <div id="services_intro"></div>

      <div id="solutions" className="container pt-2 pt-md-4">
        <div className="row justify-content-center">
          <div className="col-12 justify-text-center">
            <h2 className="title-4 text-dark text-center w-100 mb-3">
              <FormattedMessage id="Home_cust_title" />
            </h2>
          </div>
          {json.map(edge =>
            edge.node.lang == location ? (
              <div
                key={edge.node.id}
                className="col-12 col-md-6 col-lg-4 mb-2"
              >
                <Link to={edge.node.path}>
                  <div className="card feature">
                    {edge.node.image && (
                      <BackgroundImage fluid={edge.node.image.childImageSharp.fluid}
                        className="card-img-top w-100" alt={"feature-" + edge.node.title}
                      >
                        &nbsp;
                      </BackgroundImage>
                    )}
                    <div className="card-body">
                      <h4 className="card-title">{edge.node.title}</h4>
                      <p className="card-text">{edge.node.description}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null
          )}
        </div>
      </div>

      <hr />

      <div className="container services services-tart pb-6">
        <div className="grid-layout py-2">
          {services.map(edge =>
            edge.node.frontmatter.lang == props.pageContext.intl.language ? (
              edge.node.frontmatter.img ? (
                <div key={edge.node.frontmatter.path} className="grid-item span-2 mb-1">
                  <Link to={edge.node.frontmatter.path}>
                    <Panel shaded className="service service-teaser bg-light-green">
                      <div className="card-content">
                        <div className="row">
                          <BackgroundImage fluid={edge.node.frontmatter.img.childImageSharp.fluid}
                            className="col-md-3 card-kep rounded"
                          >
                            &nbsp;
                      </BackgroundImage>
                          <div className="col-md-9 card-tart pl-2">
                            <div className="service-icons">
                              {edge.node.frontmatter.icons.split(',').map((icon, i) => {
                                let match_icon = icons.find(el => el.node.title == icon);
                                return match_icon ? (
                                  <div key={i} className="service-icon center">
                                    <Img style={{ height: "2.8rem", width: "2.8rem" }} className="icon"
                                      title={match_icon.node.desc}
                                      objectFit="contain"
                                      fixed={match_icon.node.image.childImageSharp.fixed} />
                                  </div>
                                ) : null;
                              }
                              )}
                            </div>
                            <h4>{edge.node.frontmatter.title}</h4>
                            <p>{edge.node.frontmatter.desc}</p>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Link>
                </div>
              ) : (
                  <div
                    key={edge.node.frontmatter.path}
                    className="grid-item pb-1"
                  >
                    <Link to={edge.node.frontmatter.path}>
                      <Panel shaded className="service bg-light-green service-teaser">
                        <div className="card-content">
                          <div className="service-icons">
                            {edge.node.frontmatter.icons.split(',').map((icon, i) => {
                              let match_icon = icons.find(el => el.node.title == icon);
                              return match_icon ? (
                                <div key={i} className="service-icon center">
                                  <Img style={{ height: "2.8rem", width: "2.8rem" }} className="icon"
                                    title={match_icon.node.desc}
                                    objectFit="contain"
                                    fixed={match_icon.node.image.childImageSharp.fixed} />
                                </div>
                              ) : null;
                            }
                            )}
                          </div>
                          <h4>{edge.node.frontmatter.title}</h4>
                          <p>{edge.node.frontmatter.desc}</p>
                          <p>{edge.node.frontmatter.solution}</p>
                        </div>
                      </Panel>
                    </Link>
                  </div>
                )) : null
          )}
        </div>
      </div>
    </Layout>
  );
};

const servicesQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/services/" }
        frontmatter: { active: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          frontmatter {
            title
            desc
            solution
            path
            lang
            img {
              childImageSharp {
                fluid(quality: 70, maxWidth: 250) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            icons
          }
        }
      }
    }
    allIconsJson {
      edges {
        node {
          id
          title
          image {
            publicURL
            childImageSharp {
              fixed(width: 10) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image {
            childImageSharp {
              fluid(quality: 60, maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          path
          lang
        }
      }
    }
  }
`;


export default props => (
  <StaticQuery
    query={servicesQuery}
    render={data => (
      <Services location={props.location} props data={data} {...props} />
    )}
  />
)

